import { number } from "vue-types";
import { BaseSymbol } from "./model";

export const UserAPI = {
  GetCurrentUser: "/user/currentUser",
  GetPhone: "/user/captcha/phone",
  GetEmail: "/user/captcha/email",
  GetForgetPasswordSendEmail: "/user/forget/password/send/email",
  GetCheckPhone: "/user/check/phone",
  GetCheckEmail: "/user/check/email",
  GetCheckInviteCode: "/user/check/invitecode",
  GetCheckUsername: "/user/check/username",
  GetUserConfig: "/user/config/get",

  PostRegister: "/user/register",
  PostLogin: "/user/login",
  PostOutLogin: "/user/outLogin",
  PostCheckToken: "/user/checkToken",
  PostUserResetPassword: "/user/reset/password",
  PostUserResetPasswordByEmail: "/user/reset/password/email",
  PostUserConfigSave: "/user/config/save",

  GetCaptchaGenerate: "/user/captcha/generate",
  GetCaptchaImage: "/user/captcha/image",

  GetUserApiWs: "/user/api/ws",

  GetUserApiList: "/user/api/list",
  GetUserApiAll: "/user/api/all",
  PostUserApiSave: "/user/api/save",
  PostUserApiSaveActive: "/user/api/save/active",
  PostUserApiDelete: "/user/api/delete",

  GetUserApiGroupList: "/user/api/group/list",
  PostUserApiGroupSave: "/user/api/group/save",
  PostUserApiGroupDelete: "/user/api/group/delete",

  GetUserApiBalanceCurrency: "/user/api/balance/currency",
  GetUserApiBalanceUnique: "/user/api/balance/unique",
  GetUserApiBalanceList: "/user/api/balance/list",
  GetUserApiBalanceAllow: "/user/api/balance/allow",
  GetUserApiBalanceDataList: "/user/api/balance/data/list",
  GetUserApiBalanceAll: "/user/api/balance/all",

  GetUserApiPositionLeverage: "/user/api/position/leverage",
  GetUserApiPositionList: "/user/api/position/list",
  GetUserApiOpenOrderList: "/user/api/order/open/list",
  GetUserApiHistoryOrderList: "/user/api/order/history/list",

  GetUserOrderPositionList: "/user/order/position/list",
  GetUserOrderPositionCloseList: "/user/order/position/close/list",

  PostUserApiTransfer: "/user/api/transfer",
  PostUserApiAssetTransfer: "/user/api/asset/transfer",

  GetUserApiMarginAccount: "/user/api/margin/account",
  GetUserApiMarginIsolatedAccount: "/user/api/margin/isolated/account",
  GetUserApiMarginMaxBorrowed: "/user/api/margin/maxBorrowable",
  GetUserApiMarginMaxTransfer: "/user/api/margin/maxTransferable",
  GetUserApiMarginInterestHistory: "/user/api/margin/interestHistory",
  PostUserApiMarginLoanRepay: "/user/api/margin/loan-repay",
  PostUserApiMarginTransfer: "/user/api/margin/transfer",

  GetUserApiPositionSetting: "user/api/position/setting",
  PostUserApiPositionSettingUpdate: "/user/api/position/setting/update",

  // GetUserApiLeverageAssetMode: "/user/api/leverage/assetMode",
  // GetUserApiLeveragePositionMode: "/user/api/leverage/positionMode",
  // GetUserApiLeveragePositionSetting: "/user/api/leverage/positionSetting",

  // PostUserApiLeverageAssetModeUpdate: "/user/api/leverage/assetMode/update",
  // PostUserApiLeveragePositionModeUpdate:
  //   "/user/api/leverage/positionMode/update",
  // PostUserApiLeverageRatioUpdate: "/user/api/leverage/ratio/update",
  // PostUserApiLeverageMarginTypeUpdate: "/user/api/leverage/marginType/update",

  GetUserLoginLogList: "/user/loginLog/list",

  GetUserTradeTemplateList: "/user/tradetemplate/list",
  PostUserTradeTemplateSave: "/user/tradetemplate/save",
  PostUserTradeTemplateDelete: "/user/tradetemplate/delete",

  GetUserSymbolSubscribeList: "/user/symbolsubscribe/list",
  PostUserSymbolSubscribeAdd: "/user/symbolsubscribe/add",
  PostUserSymbolSubscribeRemove: "/user/symbolsubscribe/remove",

  GetSelectObserverList: "/user/select/observer/list",
  GetObserverUserApiList: "/user/observer/user/api/list",
  GetFinanceUserApiList: "/user/finance/user/api/list",
};

export const OrderAPI = {
  PostDoOrder: "/order/doOrder",
  PostClosePosition: "/order/closePosition",
  PostCancelOrder: "/order/cancelOrder",
  PostExchangeCancelOrder: "/order/exchange/cancelOrder",
  GetUserOrderList: "/order/user/list",
  GetTwapOrderList: "/order/twap/list",
  GetCurrentBailList: "/order/user/current/list",
  GetTwapOrderChildList: "/order/twap/child/list",
  GetTwapOrderChildWs: "/order/twap/child/ws",
};

export const OrderV2API = {
  PostDoOrder: "/order/v2/doOrder",
  PostClosePosition: "/order/v2/closePosition",
};

export const BaseAPI = {
  GetSymbols: "/client/base/symbols",
  GetIntervals: "/client/base/intervals",
  GetDictionary: "/client/base/dictionary",

  GetExchangeInfo: "/client/base/exchange/info",

  GetSymbolsAI: "/client/base/symbols/ai",
  GetSymbolsAIWs: "/client/base/symbols/ai/ws",
  GetSymbolsMargin: "/client/base/symbols/margin",

  GetLeverageBrackets: "/client/base/leverage/brackets",
  GetAISelectSymbol: "/client/base/symbols/ai/select",

  PostContactUs: "/client/base/contact",
  PostApplicationCareer: "/client/base/application/career",

  GetSymbolsAstockName: "/client/base/symbols/astock/name",
};

export const KlinesAPI = {
  GetKlineList: "/client/klines/list",
  GetKlineListHistory: "/client/klines/list/history",
  GetKlineListWs: "/client/klines/list/ws",
  GetKline24hData: "/client/klines/data/24h/ws",
};

export const DepthAPI = {
  GetDepth: "/client/depth/depth",
  GetDepthWs: "/client/depth/depth/ws",
  GetDepthOrderbookWs: "/client/depth/orderbook/ws",
};

export const DataAPI = {
  GetFngList: "/fng/list",
};

export const StrategyAPI = {
  GetTemplate: "/strategy/template",
  GetRobotTemplate: "/strategy/robot/template",
  GetInstanceList: "/strategy/client/instance/list", //1
  GetInstanceInfoList: "/strategy/client/instance/info/list",
  GetInstanceInfoStatVo: "/strategy/client/instance/info/stat/vo", //1
  GetInstanceInfoListRobot: "strategy/client/instance/info/list/robot",
  GetInstancePositionList: "/strategy/client/instance/position/list", //1
  GetInstanceMarks: "/strategy/client/instance/marks",

  GetNetValueData: "/strategy/netValue/data",
  GetInfoValueData: "/strategy/infoValue/data",
  PostStart: "/strategy/client/start",
  PostStop: "/strategy/client/stop",
  PostUpdate: "/strategy/client/update",

  GetRobotInstanceList: "/strategy/client/instance/robot/list",
  GetRobotInfoList: "/strategy/client/robot/info/list",
  GetRobotPositionList: "/strategy/client/robot/position/list",
  PostRobotStart: "/strategy/client/robot/start",
  PostRobotStop: "/strategy/client/robot/stop",
  GetStartTemplateList: "/strategy/client/startTemplate/list",
  PostStartTemplateSave: "/strategy/client/startTemplate/save",
  PostStartTemplateDelete: "/strategy/client/startTemplate/delete",
  GetCurrency: "/admin/strategy/selectcoin",

  GetObserverInstanceList: "/strategy/client/observer/instance/list", //1
  GetObserverInstanceInfoStatVo:
    "/strategy/client/observer/instance/info/stat/vo", //1
  GetObserverInstancePositionList:
    "/strategy/client/observer/instance/position/list", //1

  GetEnvSelect: "/strategy/client/env/select",

  GetProfitData: "/strategy/client/profit/data",
};

export const HftAPI = {
  GetHftOrderNewest: "/hft/order/newest",
  GetHftOrderList: "/hft/order/list",
  GetHftOrderListPage: "/hft/order/list/page",
  GetHftOrderIds: "/hft/order/ids",
  GetHftOrderWs: "/hft/order/ws",
};
export const MsgAPI = {
  GetUserMsgWs: "/msg/user/ws",
  GetUserMsgList: "/msg/user/list",
  PostUserMsgRead: "/msg/user/read",
  GetUserMsgLoad: "/msg/user/load",
  GetUserMsgLoadBefore: "/msg/user/load/before",
};

export const StatsAPI = {
  GetStatsMain: "/stats/main",
  GetStatsMainOnly: "/stats/main/only",
  GetStats24H: "/stats/24h",
  GetStatsRobot: "/stats/robot",
  GetStatsRobotData: "/stats/robot/data",
};

export const OptionAPI = {
  GetBookList: "/option/book/list",
  GetBookListWs: "/option/book/ws",
  GetTicker: "/option/ticker",
  GetTickerList: "/option/ticker/list",
  GetTickerListWs: "/option/ticker/list/ws",
};
export interface BaseResponse {
  code: number;
  msg: string;
  success: boolean;
  data: unknown;
}

export interface PageResponseData {
  page_size: number;
  page: number;
  total: number;
  results: unknown[];
  filters: any;
}
export interface OrderRequest {
  ex_type: string;
  ac_type: string;
  symbol: string;
  trigger_price: number;
  ori_vol: number;
  ori_qty: number;
  is_price_deviated: boolean;
  trade_type: number;
  off_set: number;
  order_mode: number;
  except_price: number;
  user_id: string;
  account_id: string;
  type: string;
  order_types: string;
  api_id: number;
}

export interface OrderV2Request {
  exchange: string;
  params: OrderV2Param[];
  api_id: number;
  sync_ratio?: number;
}
export interface OrderV2Param {
  accountType: string;
  symbol: string;
  price: number;
  quantity: number;
  orderType: string;
  orderSide: string;
  orderId: string;
  clientOrderId: string;
  positionSide: string;
  timeInForce: string;
  reduceOnly: boolean;
  newClientOrderId: string;
  isIsolated: boolean;
  isMargin: boolean;
  ccy: string;
  isAlgo?: boolean;
  orderAlgoType?: string;
  triggerPrice: number;
  triggerType: string;
  accountMode?: string;

  quantity_ccy: string;
  // 附加止盈止损 attach_oco_tp_price
  attachOcoTpOrderPrice?: number;
  attachOcoTpTriggerPrice?: number;
  attachOcoSlOrderPrice?: number;
  attachOcoSlTriggerPrice?: number;

  // 双向止盈止损
  ocoTpTriggerPx?: number;
  ocoTpOrdPx?: number;
  ocoTpOrdType?: string;
  ocoSlTriggerPx?: number;
  ocoSlOrdPx?: number;
  ocoSlOrdType?: string;
}
export interface UserRequest {
  phone?: string;
  email?: string;
  username: string;
  password: string;
  captcha_id: string;
  captcha_value: string;
}

export interface PageAPIParams {
  pageSize?: number;
  page?: number;
  sortField?: string;
  sortOrder?: number;
  [key: string]: any;
}

export interface KlineRequest {
  exchange: string;
  account_type: string;
  symbol: string;
  interval: string;
  limit: number;
}

export interface DepthRequest {
  exchange: string;
  account_type: string;
  symbol: string;
  limit?: number;
}

export interface StrategyReq {
  strategyId: string; //策略ID*
  acType: string; //交易类型* 现货:spot U合约:future 币合约:swap
  symbol: string; //交易对*
  exType?: string; //交易所
  ApiKeyInfo?: ApikeyInfo; //API信息*

  MarketMakerReq?: MarketMakerReq; //做市策略参数*
  SorReq?: SorReq; //智能路由策略参数*
  ShockGridReq?: ShockGridReq; //震荡网格策略参数
  ArbOptionReq?: ArbOptionReq; //期权套利策略参数
  ArbFactorReq?: ArbFactorReq; //因子套利策略参数
  AStockExecuteMultiReq?: AStockExecuteMultiReq; //A股執行参数
  // StrongBackReq?: StrongBackReq; //强势高抛低吸策略参数
  ShockReverseReq?: ShockReverseReq; //顺势震荡高抛低吸策略参数
  RateGridReq?: RateGridReq;
  TrendContinueRobotReq?: TrendContinueRobotReq;
  StrongGridReq?: StrongGridReq;
  startEnv?: string; //是否测试
}
export interface ApikeyInfo {
  apiId: number | undefined; //用户api*
}

export interface StrongGridReq {
  //symbolbase
  ac_type?: string; //交易类型* 现货:spot U合约:future 币合约:swap
  symbol?: string; //交易对*
  ex_type?: string; //交易所

  //utilsbase
  instance_id?: string; //实例ID
  strategy_name?: string; //实例名
  is_margin: boolean; //是否杠杆
  is_isolated: boolean; //是否逐仓杠杆
  is_post_only?: boolean; //是否只做maker,只对直接下单有效
  is_clear_position?: boolean; //是否清仓
  is_clear_all_position?: boolean; //是否全部清仓
  max_trade_qty?: number; //最大开仓金额

  //stronggrid
  is_open_pos?: boolean; //是否开仓
  is_long?: boolean; //是否做多
  buy_step_percent?: number; //买单间隔比例
  sell_step_percent?: number; //卖单间隔比例
  init_hold?: number; //初始仓位
  take_profit_price?: number; //止盈价格
  stop_loss_price?: number; //止损价格
  order_num?: number; //单笔下单量
  orderQty?: number; //单笔下单额
  kline_interval?: string; //K线周期
}

export interface TrendContinueRobotReq {
  ac_type?: string; //交易类型* 现货:spot U合约:future 币合约:swap
  symbol?: string; //交易对*
  ex_type?: string; //交易所
  instance_id?: string; //实例ID
  strategy_name?: string; //实例名
  is_margin: boolean; //是否杠杆
  is_isolated: boolean; //是否逐仓杠杆
  dk_flag?: number; //机器人支持类型 1:多 0:双向 -1:空
  max_trade_qty?: number; //单次调用工具最大可用金额
  all_max_trade_qty?: number; //总最大可用金额
  is_spot?: boolean; //是否支持现货
  n?: number; //只交易交易额前n名
  buy_step_percent?: number; //买单间隔比例
  sell_step_percent?: number; //卖单间隔比例
}
// export interface StrongBackReq {
//   instance_id?: string; //实例ID
//   strategy_name?: string; //实例名
//   is_long?: boolean; //是否做多
//   is_strong?: boolean; //是否强势判定
//   is_margin: boolean; //是否杠杆
//   is_isolated: boolean; //是否逐仓杠杆
//   kline_interval?: string; //k线周期
//   open_quantile?: number; //开仓分位数
//   close_quantile?: number; //平仓分位数
//   order_num_u?: number; //下单金额,暂时通用
//   order_num?: number; //下单量,暂时通用
//   orderQty?: number; //单笔下单额
// }
export interface ShockGridReq {
  ac_type?: string; //交易类型* 现货:spot U合约:future 币合约:swap
  symbol?: string; //交易对*
  ex_type?: string; //交易所
  instance_id?: string; //实例ID
  strategy_name?: string; //实例名
  is_post_only?: boolean; //是否只做maker,只对直接下单有效
  is_long?: boolean; //是否做多
  is_time_sharing?: boolean; //是否分时
  is_std?: boolean; //是否使用标准差
  is_follow_market?: boolean; //是否跟随市场
  is_margin: boolean; //是否杠杆
  is_isolated: boolean; //是否逐仓杠杆
  max_trade_qty?: number; //最大开仓金额
  init_hold?: number; //当前持仓
  take_profit_price?: number; //止盈价格
  stop_loss_price?: number; //止损价格
  min_percent?: number; //最小网格间隔
  max_percent?: number; //最大网格间隔
  order_num?: number; //下单量,暂时通用
  orderQty?: number; //单笔下单额
  buy_step_percent?: number; //买单间隔比例
  sell_step_percent?: number; //卖单间隔比例
  high_limit?: number; //网格区间高点
  low_limit?: number; //网格区间低点
  std_interval?: string; //标准差区间
  n?: number; //挂单对数
  is_clear_position?: boolean; //是否清仓
  is_clear_all_position?: boolean; //是否全部清仓
  is_equivalent?: boolean; //是否等比

  init_hold_ccy: string; //初始持仓币种 BTC USDT USD CONTRACT_CONT
  max_trade_qty_ccy: string; //可用金额币种 BTC USDT USD CONTRACT_CONT
  order_num_ccy: string; //下单量币种 BTC USDT USD CONTRACT_CONT

  is_not_re_quote: boolean; //是否不重新报价
  is_use_current_position: boolean; //是否使用现有仓位
}

export interface ArbOptionReq {
  ac_type?: string; //交易类型* 现货:spot U合约:future 币合约:swap
  symbol?: string; //交易对*
  ex_type?: string; //交易所
  instance_id?: string; //实例ID
  strategy_name?: string; //实例名
}

export interface ArbFactorReq {
  ac_type?: string; //交易类型* 现货:spot U合约:future 币合约:swap
  symbol?: string; //交易对*
  ex_type?: string; //交易所

  instance_id?: string; //实例ID
  strategy_name?: string; //实例名

  is_post_only?: boolean; //是否只做maker,只对直接下单有效
  is_clear_position?: boolean; //是否清仓
  is_clear_all_position?: boolean; //是否全部清仓
  max_trade_qty?: number; //最大开仓金额
  max_trade_qty_ccy: string; //可用金额币种 BTC USDT USD CONTRACT_CONT

  exec_min_order_qty: number;
  exec_max_split_order: number;
  exec_timeout: number;
  exec_order_timeout: number;
  exec_timeout_taker: boolean;
}

export interface AStockExecuteMultiReq {
  ac_type?: string; //交易类型* 现货:spot U合约:future 币合约:swap
  symbol?: string; //交易对*
  ex_type?: string; //交易所

  instance_id?: string; //实例ID
  strategy_name?: string; //实例名

  is_post_only?: boolean; //是否只做maker,只对直接下单有效
  is_clear_position?: boolean; //是否清仓
  is_clear_all_position?: boolean; //是否全部清仓
  max_trade_qty?: number; //最大开仓金额
  max_trade_qty_ccy: string; //可用金额币种 BTC USDT USD CONTRACT_CONT

  exec_min_order_qty: number;
  exec_max_split_order: number;
  exec_timeout: number;
  exec_order_timeout: number;
  exec_timeout_taker: boolean;
}

//顺势震荡高抛低吸
export interface ShockReverseReq {
  ex_type?: string; //交易所类型
  ac_type?: string; //账户类型
  symbol?: string; //symbol
  instance_id?: string; //实例ID
  strategy_name?: string; //实例名
  is_post_only?: boolean; //是否只做maker,只对直接下单有效
  // kline_interval_long?: string; // 长周期
  // kline_interval_short?: string; // 短周期
  stop_loss_percent?: number; //止损比例
  take_profit_percent?: number; //止盈比例
  order_num?: number; //总下单量,会分成十份
  orderQty?: number; //单笔下单额
  max_trade_qty?: number; //可用金额
  is_margin: boolean; //是否杠杆
  is_isolated: boolean; //是否逐仓杠杆
  is_clear_position?: boolean; //是否清仓
  is_clear_all_position?: boolean; //是否全部清仓
  dk_flag?: number;
  high_limit?: number; //网格区间高点
  low_limit?: number; //网格区间低点
  kline_interval?: string; //K线周期
}

export interface RateGridReq {
  instance_id?: string; //实例ID
  strategy_name?: string; //实例名
  is_post_only?: boolean; //是否只做maker,只对直接下单有效
  is_margin: boolean; //是否杠杆
  is_isolated: boolean; //是否逐仓杠杆
  is_clear_position?: boolean; //是否清仓
  is_clear_all_position?: boolean; //是否全部清仓

  long_symbol: RateGridSymbol; //汇率基础交易对
  short_symbol: RateGridSymbol; //汇率目标交易对
  rate_step_percent?: number; //间隔比例
  max_trade_qty?: number; //可用金额
}

export interface RateGridSymbol {
  ex_type: string; //交易所类型
  ac_type: string; //账户类型
  symbol: string; //symbol
  init_hold?: number; //可用仓位
  order_num?: number; //下单数量
  orderQty?: number; //单笔下单额
}

export interface MarketMakerReq {
  account?: string;
  isClearPosition?: boolean; //是否清仓
  isClearAllPosition?: boolean; //是否全部清仓
  accountType?: string;
  exchangeTypes?: string;
  symbolName?: string;
  instance_id?: string; //实例id
  strategy_name?: string; //实例名
  // isLong?: boolean; //是否做多
  isPostOnly: boolean; // 是否只做maker
  isStdev: boolean; // 是否启用标准差
  isTimeline: boolean; // 是否启用分时
  // isFollewMarket: boolean; // 订单是否跟随市场移动
  isMargin: boolean; //是否杠杆
  isIsolated: boolean; //是否逐仓杠杆
  orderNum?: number; // 单笔下单量
  initHoldL?: number; // 投入策略币的数量 多头数量
  initHoldS?: number; // 投入策略币的数量 空头数量
  exposureLimit?: number; // 最大开仓金额
  maxPercent?: number; // 最大间隔比例
  minPercent?: number; // 最小间隔比例
  // maxPosition?: number; // 持仓上限
  // minPosition?: number; // 持仓下限
  targetPosition?: number; // 目标仓位
  targetPercent?: number; // 当前时刻目标间隔比例
  highLimit?: number; //区间高点
  lowLimit?: number; //区间低点
  // stdevFactor?: number; //标准差系数
  // takeProfitPrice?: number; //止盈价
  // stopLossPrice?: number; //止损价
  orderQty?: number; //单笔下单额
  stdInterval?: string; //k线周期
  trendInterval?: string;

  numLevels?: number; //深度档位数量
  // priceInterval?: number; //价格间隔
  maxInventory?: number; //最大库存
  minInventory?: number; //最小库存
  windowSize?: number;
  isGrantObserver?: boolean; //是否授权观察者
  observerUserIds?: number[]; //观察者用户id

  smartPriceWindowSize?: number; // 智能价格窗口大小
  smartPriceNumLevels?: number; // 智能价格深度档位数量

  OFIFWindowSize?: number; // 智能价格窗口大小
  OFIFNumLevels?: number; // 智能价格深度档位数量
  env?: string;

  orderStep?: number; //挂单间隔

  // IsLiquidity bool    `json:"isLiquidity"` // 是否启用流动性
  // MinOrderNum float64 `json:"minOrderNum"` // 单笔最小下单量 USD
  // MaxOrderNum float64 `json:"maxOrderNum"` // 单笔最大下单量 USD
  isLiquidity?: boolean; // 是否启用流动性
  minOrderNum?: number; // 单笔最小下单量 USD
  maxOrderNum?: number; // 单笔最大下单量 USD

  isCombination?: boolean; // 是否组合
}

export interface SorReq {
  account?: string;
  instance_id?: string; //实例id
  strategy_name?: string; //实例名
  isStdev?: boolean;
  isPostOnly?: boolean;
  isTimeline?: boolean;
  isGrantObserver?: boolean;
  observerUserIds?: number[];
  env?: string;
  tpData: SorTpData[];
  levelTotal?: number;
  levelStart?: number;
  levelInterval?: number;
  impactLevel?: number;
  impactMultiple?: number;
  maxOrderAmount?: number;
  maxOrderCurrency?: string;
  isFollowBook?: boolean;
}

export interface ArbOptionJson {
  equity: number; //账户资产净值
  total_equity_usd: number; //账户资产净值(美元)
  im: number; //初始保证金率
  mm: number; //维护保证金率
  total_floating_profit_loss_usd: number; //损益
  total_profit_loss: number; //损益(美元)
  roi: number; //ROI
  total_delta: number; //Delta
  total_gamma: number; //Gamma
  total_vega: number; //Vega
  total_theta: number; //Theta
  total_rho: number; //Rho
  ccy: string; //币种
}

export interface SorTpData {
  tpId?: string;
  apiId?: string;
  accessKey?: string;
  secretKey?: string;
  exchangeType?: string;
  accountType?: string;
  symbolName?: string;
  maxTradeAmount?: number;
  orderSide?: string;
  positionSide?: string;
  orderType?: string;
  timeInForce?: string;
  baseCurrency?: string;
  quoteCurrency?: string;
  sideAndType?: string;
  symbol?: BaseSymbol;
  exceptPrice?: number;
  // impactPercent?: number;
}

export interface TransferReq {
  api_id: number;
  asset: string;
  amount: number;
  type: string;
}

export interface AssetTransferReq {
  api_id: number;
  asset: string;
  amount: number;
  from: string;
  to: string;
  from_symbol?: string;
  to_symbol?: string;
}

export interface MarginTransferReq {
  api_id: number; //必须 操作的api_id
  asset: string; //必须 划转币种
  amount: number; //必须 划转数量
  is_isolated: boolean; //必须 是否逐仓杠杆划转
  is_to_main: boolean; //必须 是否向主账户划转
  symbol: string; //逐仓必须 逐仓交易对，配合逐仓使用
}

export interface RobotReq {
  robotTemplateId: string;
  allMaxTradeQty: number;
  maxTradeQty: number;
  ApiKeyInfo: ApikeyInfo; //API信息*
}

export interface UserApiWsReq {
  req_id: string;
  req_type: string;
  currency?: string;
  exchange?: string;
  account_type?: string;
  symbol?: string;
  api_id?: number;
}

export interface UserApiWsRes {
  req: UserApiWsReq;
  res: any;
}

export interface UserApiMarginLoanRepayReq {
  api_id: number; //必须 操作的api_id
  is_loan: boolean; //必须 true:借币 false:还币
  is_isolated: boolean; //非必须 是否逐仓杠杆
  symbol: string; //非必须 逐仓交易对，配合逐仓使用
  asset: string; //必须 借币还币币种
  amount: number; //必须 借币还币数量
}

export interface UserApiMarginMaxReq {
  api_id: number;
  asset: string;
  is_isolated: boolean;
  symbol: string;
}

export interface UserApiMarginInterestHistoryReq {
  api_id: number;
  asset?: string; //NO 币种, 比如, BTC
  symbol?: string; //NO 逐仓交易对，适用于逐仓查询
  start_time?: number; //NO
  end_time?: number; //NO
  current?: number; //NO	当前查询页。 开始值 1. 默认:1
  size?: number; //NO	默认:10 最大:100
  archived?: boolean; //NO	默认: false. 查询6个月以前的数据，需要设为 true
}

export interface UserApiLeverageReq {
  api_id: number; //YES 操作的api_id
  account_type: string; //YES 合约类型
  symbol?: string; //NO 交易对
  asset_mode?: boolean; //NO 联合保证金模式 true 联合保证金 false 单币种保证金
  position_mode?: boolean; //NO 仓位模式 true 双向持仓 false 单向持仓
  leverage_number?: number; //NO 杠杆倍数
  margin_type?: boolean; //NO 保证金模式 true 逐仓 false 全仓
}

export interface ExchangeCancelOrderRequest {
  account_type: string;
  symbol: string;
  api_id_list: number[];
  open_order_id: number;
}
