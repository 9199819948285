import { defineStore, StoreDefinition } from "pinia";
import {
  User,
  BaseSymbol,
  BaseInterval,
  Dictionary,
  UserMsg,
  UserApi,
  userApiBalance,
  UserApiPositionSetting,
  UserConfig,
  UserConfigData,
} from "@/api/model";
import { $getSymbols } from "@/api/base";
import { $postUserMsgRead } from "@/api/msg";
import { stat } from "original-fs";
import { listItemMetaProps } from "ant-design-vue/lib/list/ItemMeta";

import i18n from "@/locales/index";
import {
  $getFinanceUserApiList,
  $getUserApiAll,
  $getUserApiWs,
  $getUserConfig,
  $postUserConfigSave,
} from "@/api/user";
import { message } from "ant-design-vue";
import { sleep } from "@/utils/common";
import { UserApiWsReq } from "@/api/api";
import { Guid } from "guid-typescript";
const t = i18n.global.t;

export interface ApiStateWs {
  isOpening: boolean;
  thisApiWs?: WebSocket;
}

export interface ApiState {
  api: UserApi;
  balanceMap: Map<string, userApiBalance[]>; //API余额 accountType -> balanceList
  positionSettingMap: Map<string, UserApiPositionSetting>; //API持仓设置 account_type/symbol -> setting
}

export const useMainStore = defineStore("main", {
  state: () => {
    return <
      {
        symbolsMarginMap: Map<string, string>;
        user: User | undefined;
        userApiMap: Map<number, UserApi>;

        userApiStateWs: ApiStateWs;
        userApiStateMap: Map<number, ApiState>;
        // mainUserApiState: ApiState | undefined;

        messagesMap: Map<string, UserMsg>;
        symbols: BaseSymbol[];
        intervals: BaseInterval[];
        dictionary: Map<string, Dictionary[]>;
        symbolMap: Map<string, BaseSymbol>;
        targetSymbolMap: Map<string, BaseSymbol>;
        exchangeInfoMap: Map<string, any>;
        observer_mul: number;

        currentAccountTypeFilterSelect: string[];

        userConfigData: UserConfigData | undefined;
      }
    >{
      user: undefined,
      userApiMap: new Map(),

      userApiStateWs: {
        isOpening: false,
        thisApiWs: undefined,
      },
      userApiStateMap: new Map(),
      // mainUserApiState: undefined,

      messagesMap: new Map(),
      symbols: [],
      symbolsMarginMap: new Map(),
      intervals: [],
      dictionary: new Map(),
      symbolMap: new Map(),
      targetSymbolMap: new Map(),
      exchangeInfoMap: new Map(),
      observer_mul: 1,

      currentAccountTypeFilterSelect: [],
      userConfig: undefined,
      userConfigData: undefined,
    };
  },
  getters: {
    inviteCode: (state) => state.user?.invite_code,
    role: (state) => state.user?.role,
    token: (state) => state.user?.token,
    dealMessages: (state) => {
      const list: UserMsg[] = [];
      state.messagesMap.forEach((val) => {
        if (val.type == "1") {
          list.push(val);
        }
      });
      const newList = list.sort((a, b) => {
        return b.msg_time - a.msg_time;
      });
      return newList;
    },
    announcementMessages: (state) => {
      const list: UserMsg[] = [];
      state.messagesMap.forEach((val) => {
        if (val.type == "2") {
          list.push(val);
        }
      });
      const newList = list.sort((a, b) => {
        return b.msg_time - a.msg_time;
      });
      return newList;
    },
    systemMessages: (state) => {
      const list: UserMsg[] = [];
      state.messagesMap.forEach((val) => {
        if (val.type == "3") {
          list.push(val);
        }
      });
      const newList = list.sort((a, b) => {
        return b.msg_time - a.msg_time;
      });
      return newList;
    },
    dealMessageNotReadCount: (state) => {
      let count = 0;
      state.messagesMap.forEach((val) => {
        if (!val.is_read && val.type == "1") {
          count++;
        }
      });
      return count;
    },
    announcementMessageNotReadCount: (state) => {
      let count = 0;
      state.messagesMap.forEach((val) => {
        if (!val.is_read && val.type == "2") {
          count++;
        }
      });
      return count;
    },
    systemMessageNotReadCount: (state) => {
      let count = 0;
      state.messagesMap.forEach((val) => {
        if (!val.is_read && val.type == "3") {
          count++;
        }
      });
      return count;
    },
    username: (state) => {
      return state.user?.username;
    },
  },
  actions: {
    setUser(user: User | undefined) {
      this.user = user;
    },
    getUser() {
      return this.user;
    },
    setUserApiMap(list: UserApi[]) {
      const m: Map<number, UserApi> = new Map();
      list.forEach((v) => {
        m.set(v.id, v);
      });
      this.userApiMap = m;
    },
    getUserApiMap() {
      return this.userApiMap;
    },
    updateUserApiStateBalance(api: UserApi, balanceList: userApiBalance[]) {
      let apiState: ApiState = this.userApiStateMap.get(api.id)!;
      if (apiState == undefined) {
        apiState = {
          api: api,
          balanceMap: new Map(),
          positionSettingMap: new Map(),
        };
        this.userApiStateMap.set(api.id, apiState);
      }
      const newMap: Map<string, userApiBalance[]> = new Map();
      for (const balance of balanceList) {
        const list = newMap.get(balance.account_type);
        if (list == undefined) {
          newMap.set(balance.account_type, [balance]);
        } else {
          list.push(balance);
        }
      }
      apiState.balanceMap = newMap;
      // if (api.is_main) {
      //   this.mainUserApiState = apiState;
      // }
      // console.log(apiState, "ccccccccccccccccc");
    },
    updateUserApiStatePositionSetting(
      api: UserApi,
      setting: UserApiPositionSetting
    ) {
      let apiState: ApiState = this.userApiStateMap.get(api.id)!;
      if (apiState == undefined) {
        apiState = {
          api: api,
          balanceMap: new Map(),
          positionSettingMap: new Map(),
        };
        this.userApiStateMap.set(api.id, apiState);
      }
      apiState.positionSettingMap.set(
        [setting.account_type, setting.symbol].join("/"),
        setting
      );
      // if (api.is_main) {
      //   this.mainUserApiState = apiState;
      // }
    },
    closeApiStateWs() {
      if (this.userApiStateWs.thisApiWs != undefined) {
        this.userApiStateWs.thisApiWs.close();
        this.userApiStateWs.thisApiWs = undefined;
      }
    },
    async openApiStateWs() {
      if (this.userApiStateWs.isOpening) {
        return;
      } else {
        this.userApiStateWs.isOpening = true;
      }

      this.closeApiStateWs();
      const onopen = () => {
        // console.log("this api ws open");
      };
      const onclose = () => {
        // console.log("this api ws close need reconnect");
        setTimeout(() => {
          this.openApiStateWs();
        }, 1000);
      };
      const onerror = (evt: any) => {
        console.error(evt);
      };
      const onmessage = (evt: any) => {
        try {
          const d = JSON.parse(evt.data);
          // console.log(d);
          if (d != undefined) {
            switch (d.req.req_type) {
              case "apiBalance": {
                const list = d.res as userApiBalance[];
                // console.log(list, "aaaaaaaaaaaa");
                const m: Map<number, userApiBalance[]> = new Map();
                for (const balance of list) {
                  let balanceList = m.get(balance.api_id);
                  if (balanceList == undefined) {
                    balanceList = [];
                    m.set(balance.api_id, balanceList);
                  }
                  balanceList.push(balance);
                }
                for (const item of m) {
                  const userApi = this.userApiMap.get(item[0]);
                  if (userApi != undefined) {
                    this.updateUserApiStateBalance(userApi, item[1]);
                  }
                }
                // console.log(m, "bbbbbbbbbbbbbbb");
                break;
              }
              case "apiPositionSetting": {
                const positionSetting = d.res as UserApiPositionSetting;
                const userApi = this.userApiMap.get(positionSetting.api_id);
                if (userApi != undefined) {
                  this.updateUserApiStatePositionSetting(
                    userApi,
                    positionSetting
                  );
                }
                break;
              }
              default:
                console.error("req type error");
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      const { ws } = $getUserApiWs({}, onopen, onmessage, onerror, onclose);
      this.userApiStateWs.thisApiWs = ws;

      while (
        this.userApiStateWs.thisApiWs == undefined ||
        this.userApiStateWs.thisApiWs.readyState !==
          this.userApiStateWs.thisApiWs.OPEN
      ) {
        await sleep(500);
      }
      this.userApiStateWs.isOpening = false;
    },

    sendApiStateWsReq(req: UserApiWsReq) {
      if (this.user == undefined) {
        return;
      }
      if (
        this.userApiStateWs.thisApiWs != undefined &&
        this.userApiStateWs.thisApiWs.readyState ===
          this.userApiStateWs.thisApiWs.OPEN
      ) {
        this.userApiStateWs.thisApiWs.send(JSON.stringify(req));
        return true;
      } else {
        return false;
      }
    },
    refreshApiStateBalance(api_id: number) {
      const thisReq: UserApiWsReq = {
        req_id: Guid.create().toString(),
        req_type: "apiBalance",
        api_id: api_id,
      };
      this.sendApiStateWsReq(thisReq);
    },
    refreshApiStatePositionSetting(api_id: number, symbol: BaseSymbol) {
      const thisReq: UserApiWsReq = {
        req_id: Guid.create().toString(),
        req_type: "apiPositionSetting",
        api_id: api_id,
        exchange: symbol.exchange,
        account_type: symbol.account_type,
        symbol: symbol.symbol,
      };
      this.sendApiStateWsReq(thisReq);
    },
    async refreshApiMap() {
      let $apiFunc = $getUserApiAll;
      if (this.user == undefined) {
        return;
      }
      // console.log(this.user.role);
      if (
        this.user.role == "observer" ||
        this.user.role == "finance" ||
        this.user.role == "risk"
      ) {
        $apiFunc = $getFinanceUserApiList;
      } else {
        $apiFunc = $getUserApiAll;
      }
      const res2 = await $apiFunc({});
      if (res2.code == 200) {
        const list = res2.data as UserApi[];
        this.setUserApiMap(list);
      }
    },
    async refreshUserConfig() {
      if (this.user == undefined) {
        return;
      }
      const res = await $getUserConfig({});
      if (res.code == 200) {
        const config = res.data as UserConfig;
        try {
          this.userConfigData = JSON.parse(
            config.config_json
          ) as UserConfigData;
        } catch (err) {
          console.error(err);
        }
      }
      if (this.userConfigData == undefined) {
        this.userConfigData = {
          api_select_list: [],
        };
      }
    },
    async saveUserConfig() {
      if (this.user == undefined) {
        return;
      }
      const config = JSON.stringify(this.userConfigData);
      const res = await $postUserConfigSave({
        config_json: config,
      });
    },
    addMessage(message: UserMsg) {
      this.messagesMap.set(message.id, message);
    },
    getMessage(type: string) {
      const list: UserMsg[] = [];
      this.messagesMap.forEach((val) => {
        if (val.type == type) {
          list.push(val);
        }
      });
      const newList = list.sort((a, b) => {
        return b.msg_time - a.msg_time;
      });
      return newList;
    },
    readMessage(list: UserMsg[]) {
      $postUserMsgRead(list).then((res) => {
        if (res.code == 200) {
          // console.log("deal msg success read");
          list.forEach((val) => {
            this.messagesMap.get(val.id)!.is_read = true;
          });
        }
      });
    },
    getMessgeNotReadCount(type: string) {
      let count = 0;
      this.messagesMap.forEach((val) => {
        if (!val.is_read && val.type == type) {
          count++;
        }
      });
      return count;
    },

    setSymbols(symbols: BaseSymbol[]) {
      this.symbols = symbols;
      symbols.forEach((v) => {
        this.symbolMap.set([v.exchange, v.symbol, v.account_type].join("/"), v);
        this.targetSymbolMap.set(
          [v.exchange, v.symbol, v.account_type].join("/"),
          v
        );
      });
      // console.log(this.symbolMap);
    },
    getSymbols() {
      if (this.symbols.length == 0) {
        $getSymbols({}).then((res) => {
          if (res.code == 200) {
            this.setSymbols(res.data as BaseSymbol[]);
          }
        });
      }
      return this.symbols;
    },
    setSymbolsMargin(data: any) {
      // console.log(data);
      data.both.forEach((symbol: string) => {
        this.symbolsMarginMap.set(symbol, "both");
      });
      data.only_isolated.forEach((symbol: string) => {
        this.symbolsMarginMap.set(symbol, "only_isolated");
      });
      data.only_margin.forEach((symbol: string) => {
        this.symbolsMarginMap.set(symbol, "only_margin");
      });
      // console.log(this.symbolsMarginMap, "dhh");
    },
    getSymbolsMargin(symbol: string) {
      const type = this.symbolsMarginMap.get(symbol);
      return type;
    },
    getSymbolsMarginFromType(targetType: string) {
      const list: string[] = [];
      for (const item of this.symbolsMarginMap) {
        if (item[1] == targetType || item[1] == "both") {
          list.push(item[0]);
        }
      }
      return list;
    },

    setIntervals(intervals: BaseInterval[]) {
      this.intervals = intervals;
    },
    getIntervals() {
      return this.intervals;
    },
    setDictionary(dictionary: Dictionary[]) {
      dictionary.forEach((value) => {
        if (this.dictionary.get(value.code) == undefined) {
          this.dictionary.set(value.code, []);
        }
        this.dictionary.get(value.code)!.push(value);
      });
    },
    getDictionary(code: string) {
      return this.dictionary.get(code);
    },

    handleDictionary(code: string, key: string) {
      const list = this.dictionary.get(code);
      let result = key;
      list?.forEach((value) => {
        if (value.key == key) {
          // console.log(value.key, key, value.key == key, value.value);
          result = value.value;
          return;
        }
      });
      if (result != "") {
        return t(`Dictionary.${result}`);
      } else {
        return "";
      }
    },
    getSymbolByKey(key: string) {
      return this.symbolMap.get(key)!;
    },
    getTargetSymbolBySymbolAndAcType(
      exchange: string,
      symbol: string,
      ac_type: string
    ) {
      return this.targetSymbolMap.get([exchange, symbol, ac_type].join("/"))!;
    },
    getExchangeInfoByKey(key: string) {
      return this.exchangeInfoMap.get(key);
    },
    setExchangeInfo(key: string, value: any) {
      this.exchangeInfoMap.set(key, value);
    },
    checkNotUserLogin() {
      if (this.user?.token == undefined) {
        return true;
      }
      return false;
    },
    checkNotVipExchangePermission() {
      if (this.user?.vip_level == undefined || this.user.vip_level < 1) {
        return true;
      }
      return false;
    },
    checkNotVipJzjPermission() {
      if (this.user?.vip_level == undefined || this.user.vip_level < 2) {
        return true;
      }
      return false;
    },
    checkNotVipStrategyPermission() {
      if (this.user?.vip_level == undefined || this.user.vip_level < 1) {
        return true;
      }
      return false;
    },
    checkNotMarketMakerPermission() {
      if (this.user?.vip_level == undefined || this.user.vip_level < 3) {
        return true;
      }
      return false;
    },
  },
});
// as StoreDefinition<
//   "main",
//   {
//     user: User | undefined;
//     messages: Messages;
//     symbols: BaseSymbol[];
//     intervals: BaseInterval[];
//     dictionary: Map<string, Dictionary[]>;
//     symbolMap: Map<string, BaseSymbol>;
//   },
//   {
//     role: (state: any) => string;
//     token: (state: any) => string;
//     messageCount: (state: any) => number;
//     dealMessages: (state: any) => Messages[];
//     announcementMessages: (state: any) => Messages[];
//     systemMessages: (state: any) => Messages[];
//   },
//   {
//     setUser(user: User | undefined): void;
//     getUser(): User | undefined;
//     getMessages(): Messages;
//     setMessages(messages: Messages): void;
//     clearMessage(key: string): void;
//     setSymbols(symbols: BaseSymbol[]): void;
//     getSymbols(): {
//       exchange: string;
//       currency: string;
//       valuation: string;
//       spot_symbol: string;
//       swap_symbol: string;
//       future_symbol: string;
//     }[];
//     setIntervals(intervals: BaseInterval[]): void;
//     getIntervals(): {
//       exchange: string;
//       interval: string;
//     }[];
//     setDictionary(dictionary: Dictionary[]): void;
//     getDictionary(code: string): Dictionary[] | undefined;
//     handleDictionary(code: string, key: string): string;
//     getSymbolByKey(key: string): BaseSymbol;
//   }
// >;
